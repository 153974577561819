import omitBy from 'lodash/omitBy';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

export default {
  computed: {
    query() {
      const { query } = this.$route;

      return omitBy(
        {
          aid: get(query, 'aid'),
          ps: get(query, 'ps'),
          cid: get(query, 'cid', get(query, 'cId')),
          email: get(query, 'email'),
          order_id: get(query, 'order_id', get(query, 'iorderid')),
          target_ref: get(query, 'target_ref'),
        },
        isNil,
      );
    },
  },
};
