
import get from 'lodash/get';
import queryMixin from '~/mixins/query';
import { getCookie, nginxDecodeUserId } from '~/helpers/cookieHelpers';

export default {
  name: 'Login',
  auth: 'guest',
  mixins: [queryMixin],
  middleware: ['automaticLogin'],
  asyncData({ store, redirect, $dynamicRouter, route, req }) {
    const { query } = route;
    const host = req?.headers?.host;
    const value = {
      cid: get(query, 'cid', get(query, 'cId')),
      email: get(query, 'email'),
      order_id: get(query, 'order_id', get(query, 'iorderid')),
    };

    // This prevents automatic 302, in case the user is already logged in but an automatic login is possible.
    if ((value.cid || value.email) && value.order_id) {
      return { host };
    }

    if (store.getters['auth/loggedIn']) {
      redirect({
        status: 302,
        path: $dynamicRouter.toLocaleRoute('/account/overview'),
      });
    }

    return { host };
  },
  data() {
    return {
      navigation: [
        {
          title: 'Home',
          href: '/',
        },
        {
          title: this.$i18n('customer.login-title'),
          href: '/',
        },
      ],
    };
  },
  head() {
    const script = [];

    if (this.$store?.getters?.experimentVariant('BE1356CookieBanner') === 'b') {
      script.push({
        id: 'Cookiebot',
        src: 'https://consent.cookiebot.com/uc.js',
        'data-cbid': 'ea7b04b3-d681-4591-99a4-50bdcf5fa1d5',
        'data-culture': this.$store.state.language.lang,
        type: 'text/javascript',
        async: true,
      });
    }

    return {
      title: this.$helpers.pageTitle(this.$i18n('templates.my-account-page-title-login')),
      script,
      link: [
        {
          rel: 'canonical',
          href: `https://${this.host}${this.$nuxt.$route.path}`,
        },
        ...this.$store.state.languages.map(({ domain, lang }) => ({
          rel: 'alternate',
          hreflang: lang === 'en-eu' ? 'en' : lang,
          href: `https://${domain}/login/`,
        })),
      ],
    };
  },
  computed: {
    hasCID() {
      return this.query.cid && this.query.cid.toString().trim().length > 0;
    },
    targetPath() {
      const targetRef = this.query.target_ref;

      return (
        {
          'edit-reservation': this.$helpers.getEditReservationLink(this.query),
          'reviews-add': this.$dynamicRouter.toLocaleRoute(`/account/review/add/${this.query.order_id}`),
        }[targetRef] ?? this.$dynamicRouter.toLocaleRoute('/account/overview')
      );
    },
  },
  async mounted() {
    await this.$store.dispatch('experimentVariant', {
      name: 'BE1356CookieBanner',
    });
    window.addEventListener('CookiebotOnDialogDisplay', () => {
      window.dataLayer.push({
        event: 'experiment_data',
        dl_experiment_id: 'BE-1356',
        dl_experiment_action: 'impression',
        dl_experiment_value: 'banner',
      });
      const configure = document.querySelector('#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection');
      const selectAll = document.querySelector('#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll');

      setTimeout(() => {
        selectAll?.addEventListener('click', () => {
          setTimeout(() => {
            window.dataLayer.push({
              event: 'experiment_data',
              dl_experiment_id: 'BE-1356',
              dl_experiment_action: 'selection',
              dl_experiment_value: 'accept_all',
            });
            this.sendEvent('accept_all', 'Action Tracking', 'cookieBanner');
          });
        });

        configure?.addEventListener('click', () => {
          setTimeout(() => {
            window.dataLayer.push({
              event: 'experiment_data',
              dl_experiment_id: 'BE-1356',
              dl_experiment_action: 'selection',
              dl_experiment_value: 'accept_selection',
            });
            this.sendEvent('accept_selection', 'Action Tracking', 'cookieBanner');
          });
        });
      });
      window.addEventListener(
        'CookiebotOnDecline',
        () => {
          if (window.Cookiebot.changed) {
            window.dataLayer.push({
              event: 'experiment_data',
              dl_experiment_id: 'BE-1356',
              dl_experiment_action: 'selection',
              dl_experiment_value: 'decline_all',
            });
            this.sendEvent('decline_all', 'Action Tracking', 'cookieBanner');
          }
        },
        false,
      );
    });
    this.$store.commit('bucket/update', { key: 'page_type', value: 'my_account_login' });
    this.$store.commit('bucket/update', {
      key: 'page_view_virtual',
      value: `https://${this.$store.state.language.domain}/login`,
    });

    // The following line ensures that the bucket is ready before the pageview is sent.
    await window.daBucket.ready();

    window.waiting_on_subsequent_pageview = false;
    window.waiting_on_initial_pageview = false;
  },
  methods: {
    async sendEvent(ea, ec, el) {
      const { host } = window.location;
      const pageType = () => window.$nuxt?.$store?.state?.bucket?.page_type || window.pageType;

      const visitorIdCookie = getCookie('uid');
      if (!visitorIdCookie) {
        console.log('[FLAGSHIP GTM] VisitorId is not defined');
        return;
      }
      const visitorId = nginxDecodeUserId(visitorIdCookie);
      const flagshipEnvironmentId = this.$flagshipEnvironmentId;
      const flagshipEventsAPIEndpoint = this.$config.FLAGSHIP_EVENTS_API_BASE_URL;

      const response = await fetch(`${flagshipEventsAPIEndpoint}?host=${host}&app=${pageType()}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cid: flagshipEnvironmentId,
          vid: visitorId,
          dl: document.location.href,
          ds: 'APP',
          ev: 1,
          t: 'EVENT',
          ea,
          ec,
          el,
        }),
      });
      if (response.status !== 200) {
        console.error(`[FLAGSHIP GTM] Event could not be be sent, HTTP ${response.status}`);
      }
    },
    onLoggedIn() {
      const targetRef = this.query.target_ref;

      if (targetRef === 'edit-reservation') {
        window.location.href = this.targetPath;
        return;
      }

      this.$router.replace({ path: this.targetPath });
    },
  },
};
